<template>
  <main-view>
    <template #body>
      <div class="stories">
        <brand-navigate :brands="brands" class="pb-4"/>
        <div v-if="!upload" class="d-flex justify-content-center">
          <fast-spinner/>
        </div>
        <div v-else>
          <div class="d-flex flex-row flex-nowrap
                      justify-content-between
                      align-items-center
                      pb-2">
            <div>
              <button class="btn btn-primary btn-text-add-story"
                      @click="$router.push('/stories/add')">
                <span class="icon-plus"/>
                Добавить историю
              </button>
            </div>
            <div>
              <div class="search-container">
                <input class="form-control search"
                       placeholder="Поиск"
                       v-model="search"
                />
                <div class="search-icon">
                  <span class="icon-search"></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table v-if="!!stories"
                   class="table bordered table-wrapper">
              <thead class="table-dark">

              <tr class="text-uppercase">
                <th v-for="column in tableColumnsActiveStories"
                    :key="column.key"
                    scope="col"
                    class="text-nowrap text-500"
                    :class="column.class"
                >
                  {{ column.label }}
                </th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(story,index) in filteredStories"
                  :key="story.id">
                <td class="td-active">
                  <div class="me-1 ms-2">
                    <img src='@/assets/img/active.png'
                         alt=""
                         v-if="story.active"
                         id="`active-${{story.id}}`"
                         @click="disActivate({
                              id: story.id,
                              index:index,
                              uuid: story.uuid,
                              active:story.active
                           })">
                    <img src="@/assets/img/disactive.png"
                         alt=""
                         v-else
                         id="`disactive-${{story.id}}`"
                         @click="activate({
                              id: story.id,
                              index:index,
                              uuid: story.uuid,
                              active:true
                           })">
                  </div>
                </td>
                <td class="td-preview">
                  <div class="preview_container me-4 ms-1" v-if="story.preview">
                    <img :src="story.preview.image"
                         loading="lazy"
                         alt="Обложка"/>
                  </div>
                </td>
                <td>
                  {{ story.sort_id }}
                  <span :class="story.fixed ? 'icon-lock text-primary':'icon-unlock'"
                        class="icon_info ms-1">
          </span>
                </td>
                <td>
                  {{ story.start_date ? parseDate(story.start_date).dateAndTime : '-' }}
                  <br>
                  {{ story.end_date ? parseDate(story.end_date).dateAndTime : '-' }}
                </td>
                <td class="text-center">
                  <div :class="story.likes > 0 ? 'text-primary':'text-disabled'">
              <span class="icon-likes"
              />
                    {{ story.likes }}
                  </div>
                  <div :class="story.dislikes > 0 ? 'text-secondary':'text-disabled'">
                    <span class="icon-dislikes"/>
                    {{ story.dislikes }}
                  </div>
                </td>
                <td>{{ story.preview.title }}</td>
                <td>
                  {{ story.deeplink }}
                </td>
                <td>
                  <button class="btn btn-light" @click="$router.push(`/stories/edit/${story.id}`)">
              <span class="icon-edit icon_action">

              </span>
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-light"
                          @click="showModalDelete({id: story.id, index:index, uuid: story.uuid})"
                  >
                    <span class="icon-trash icon_action"></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <modal-delete-story title="Удалить" ref="deleteModal">
            <template #body v-if="uploadDelete">
              <div class="mb-4">
                Вы уверены, что хотите удалить эту историю?
              </div>
              <button @click="handleDeleteStory"
                      class="btn btn-primary mb-2 px-4">
                Удалить
              </button>
            </template>
            <template #body v-else>
              <div class="spinner-border spinner-border-sm text-primary  mb-2"
                   role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #footer/>
          </modal-delete-story>
          <modal-delete-story title="Снять с публикации" ref="activeModal">
            <template #body v-if="uploadDisActive">
              <div class="mb-4">
                Вы уверены, что хотите снять историю с публикации?
              </div>
              <div class="flex-row">
                <button @click="handleDisActiveStory"
                        class="btn btn-primary px-4 me-3">
                  Да
                </button>
                <button class="btn btn-secondary px-4"
                        @click="hideModalDisAcive"
                >
                  Нет
                </button>
              </div>
            </template>
            <template #body v-else>
              <div class="spinner-border spinner-border-sm text-primary  mb-2"
                   role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #footer/>
          </modal-delete-story>
        </div>
      </div>
    </template>
  </main-view>
</template>
<script setup>
import BrandNavigate from '@/components/BrandNavigate'
import FastSpinner from '@/components/FastSpinner'
import ModalDeleteStory from '@/components/modal/ModalDeleteStory'
import {
  computed, ref, watch,
} from 'vue'
import { useStore } from 'vuex'
import { useFormat } from '@/utils/utils'
import { useRoute } from 'vue-router'
import MainView from '@/views/MainView'
import { useStaticData, useStories, useBrands } from './useAdmin'

const upload = ref(false)

const { parseDate } = useFormat()

const { tableColumnsActiveStories } = useStaticData()

const store = useStore()

const {
  fetchBrands,
  fetchedBrands,
  setBrands,
} = useBrands()

const retrievedObject = localStorage.getItem('admin_brand')
const brandLS = retrievedObject ? JSON.parse(retrievedObject) : null
const setBrand = (brand) => store.commit('setBrand', brand)

const stories = computed(() => store.state.stories)
const brand = computed(() => store.state.brand)
const brands = computed(() => store.state.brands)

const {
  fetchStories,
  deleteStory,
  activeStory,
} = useStories()
const controller = ref(null)
const controllerAbort = () => {
  if (controller.value) {
    controller.value.abort()
  }
}
const uploadStories = (brand_id) => {
  controllerAbort()
  controller.value = new AbortController()
  fetchStories({
    basket: false,
    brand_id,
    method: 'SET_STORIES',
    controller,
  })
    .then(() => {
      upload.value = true
    })
    .catch(
      (err) => {
        console.error('Error', err)
      },
    )
}
watch(brand, async (newBrand, oldBrand) => {
  if (oldBrand) {
    upload.value = false
    uploadStories(newBrand.brand_id)
  }
})
fetchBrands()
  .then((res) => {
    setBrands(res)
    if (!brand.value) setBrand(brandLS || res[0])
    fetchedBrands.value = true
    uploadStories(brand.value.brand_id)
  })
  .catch((err) => {
    console.error(err)
  })

const search = ref(null)

const filteredStories = computed(() => stories.value
  .filter((e) => !search.value || (!!e
    && !!e.preview && !!e.preview.title && e.preview.title
    .toLowerCase()
    .includes(search.value.toLowerCase()))))

const deleteModal = ref(null)
const storyForDelete = ref(null)
const uploadDelete = ref(false)

const showModalDelete = ({
  id,
  index,
  uuid,
}) => {
  storyForDelete.value = {
    id,
    index,
    uuid,
  }
  deleteModal.value.show()
  uploadDelete.value = true
}
const hideModalDelete = () => {
  deleteModal.value.hide()
}

const handleDeleteStory = () => {
  uploadDelete.value = false
  if (storyForDelete.value) {
    deleteStory(storyForDelete.value)
      .then(() => {
        stories.value.splice(storyForDelete.value.index, 1)
        hideModalDelete()
        uploadDelete.value = true
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

// Активация / Деактивация сторей
const uploadDisActive = ref(false)
const storyForDisabled = ref(false)
const activeModal = ref(null)
const disActivate = ({
  id,
  index,
  uuid,
  active,
}) => {
  storyForDisabled.value = {
    id,
    index,
    uuid,
    active: false,
  }
  activeModal.value.show()
  uploadDisActive.value = true
}
const activate = ({
  id, index, uuid, active,
}) => {
  activeStory({
    id, index, uuid, active,
  })
    .then(() => {
      stories.value[index].active = true
    })
    .catch((err) => {
      console.log(err)
    })
}

const hideModalDisAcive = () => {
  activeModal.value.hide()
}
const handleDisActiveStory = () => {
  uploadDisActive.value = false
  if (storyForDisabled.value) {
    activeStory(storyForDisabled.value)
      .then(() => {
        stories.value[storyForDisabled.value.index].active = false
        hideModalDisAcive()
        uploadDisActive.value = true
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
//

</script>

<style scoped>
.search-container {
  position: relative;
  overflow: hidden;
  width: 20rem;
}

.search-icon {
  position: absolute;
  right: .9rem;
  top: 50%;
  transform: translateY(-50%);
  color: #808C95;
  font-size: 23px;
}

.search {
  max-width: 19rem;
  border: 1px solid #C7C7C7;
  margin: .5rem;
}

.preview_container {
  position: relative;
  overflow: hidden;
  width: 79px;
  height: 79px;
  border-radius: 10px;
}

.preview_container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.td-preview {
  width: 80px;
}

.td-active {
  width: 54px;
}

.icon_action {
  font-size: 20px;
  color: #6E6B7B;
}

.icon_info {
  font-size: 20px;
}

.min-vw-30 {
  min-width: 30vw !important;
}
</style>
