<template>
  <div class="d-flex flex-column flex-shrink-0">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item" v-for="menuitem in menu" :key="menuitem.name">
        <router-link :to="menuitem.to"
                     class="nav-link
                     nav-link-padding
                     d-flex
                     text-white
                     pt-4 pb-4"
                     :class="$route.path.startsWith(menuitem.to) ? 'active ':''">
          <span class="me-2 nav-link-icon d-inline-block"
                :class="menuitem.icon"/>
          <span class="d-inline-block">
            {{ menuitem.name }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
const menu = [
  // { name: 'О нас', to: '/about', icon: 'icon-menu_about' },
  {
    name: 'Истории',
    to: '/stories',
    icon: 'icon-menu_stories',
  },
  {
    name: 'Корзина',
    to: '/basket',
    icon: 'icon-menu_basket',
  },
  // { name: 'Программа лояльности', to: '/loyalty', icon: 'icon-menu_loyalty' },
  // { name: 'Контакты', to: '/contacts', icon: 'icon-phone-call' },
  {
    name: 'Меню',
    to: '/menu',
    icon: 'icon-menu_menu',
  },
]
</script>

<style scoped>
.nav-link-padding {
  padding-left: 60px;
}

.nav-link-icon {
  font-size: 24px;
}

.active > .nav-link-icon {
  color: #FE5000;
}
</style>
