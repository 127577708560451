import { $themeConfig } from '@/../themeConfig'
import axios from 'axios'

export default {
  fetchStories({ commit }, {
    basket,
    brand_id,
    method,
    controller,
  }) {
    return new Promise((resolve, reject) => {
      const strGet = `${$themeConfig.app.backUrl}/stories?brand_id=${brand_id}&basket=${basket}`
      axios
        .get(
          strGet,
          { signal: controller.value.signal },
        )
        .then((response) => {
          commit(method, response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchStory(cnt, id) {
    return new Promise((resolve, reject) => {
      const strGet = `${$themeConfig.app.backUrl}/story/${id}`
      axios
        .get(strGet)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  postStory(cnt, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${$themeConfig.app.backUrl}/story`,
          formData.value,
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  postImg(ctx, {
    content,
    uuid,
  }) {
    const formData = new FormData()
    formData.append('img', content)
    formData.append('uuid', uuid)
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${$themeConfig.app.backUrl}/img`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchBrands() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${$themeConfig.app.backUrl}/brands`,
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteStory(ctx, {
    id,
    uuid,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${$themeConfig.app.backUrl}/story/${id}`,
          {
            basket: true,
            active: false,
            uuid,
          },
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activeStory(ctx, {
    id,
    uuid,
    active,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${$themeConfig.app.backUrl}/story/${id}`,
          {
            active,
            uuid,
          },
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recoveryStory(ctx, {
    id,
    uuid,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${$themeConfig.app.backUrl}/story/${id}`,
          {
            basket: false,
            uuid,
          },
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchStory(ctx, formData) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${$themeConfig.app.backUrl}/story/${formData.value.id}`,
          formData.value,
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout() {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${$themeConfig.app.backUrlAuth}/logout`,
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchMenu(ctx, {
    controller,
    menu_id,
  }) {
    const strGet = `${$themeConfig.app.backUrl}/menu?menu_id=${menu_id}`

    return new Promise((resolve, reject) => {
      axios
        .get(
          strGet,
          {
            headers: {
              'Content-Type': 'application/pdf',
            },
            responseType: 'blob',
            signal: controller.value.signal,
          },
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchMenuList({ commit }, { brand_id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${$themeConfig.app.backUrl}/menu-list?brand_id=${brand_id}`,
        )
        .then((response) => {
          commit('SET_MENU_LIST', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  postMenu({ commit }, {
    content,
    brand,
    name,
    index,
  }) {
    const strPost = `${$themeConfig.app.backUrl}/menu?brand_id=${brand}`
    const formData = new FormData()
    if (content) formData.append('file', content)
    if (name) formData.append('name', name)
    return new Promise((resolve, reject) => {
      axios
        .post(
          strPost,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(({ data }) => {
          commit('SET_MENU_ITEM_ID', { index, id: data.id })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  patchMenu(ctx, {
    content,
    brand,
    name,
    menu_id,
  }) {
    const strPost = `${$themeConfig.app.backUrl}/menu?brand_id=${brand}`
    const formData = new FormData()
    if (content) formData.append('file', content)
    if (name) formData.append('name', name)
    if (menu_id) formData.append('menu_id', menu_id)
    return new Promise((resolve, reject) => {
      axios
        .patch(
          strPost,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  dropMenu({ commit }, { menuId, index }) {
    const strPost = `${$themeConfig.app.backUrl}/drop-menu?menu_id=${menuId}`
    return new Promise((resolve, reject) => {
      axios
        .post(strPost)
        .then(() => {
          commit('DELETE_MENU_ITEM', index)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
