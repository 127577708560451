export default {
  setBrand(state, brand) {
    state.brand = brand
    localStorage.setItem('admin_brand', JSON.stringify(brand))
  },

  SET_STORIES(state, stories) {
    state.stories = stories
  },
  SET_BASKET_STORIES(state, stories) {
    state.basketStories = stories
  },
  SET_BRANDS(state, brands) {
    state.brands = brands
  },
  SET_MENU_LIST(state, menuList) {
    state.menuList = menuList
  },
  ADD_MENU_LIST(state, menuItem) {
    state.menuList.push(menuItem)
  },
  SET_MENU_ITEM_NAME(state, { index, name }) {
    state.menuList[index].name = name
  },
  SET_MENU_ITEM_ID(state, { index, id }) {
    state.menuList[index].id = id
  },
  DELETE_MENU_ITEM(state, index) {
    state.menuList.splice(index, 1)
  },
}
